export default {
	colors: {
		//! Primary Colors
		primaryColor1: '#9C7754',
		primaryColor2: '#7B5632',
		primaryColor3: '#B5997F',
		primaryColor4: '#CEBBAA',
		primaryColor5: '#E6DDD4',
		primaryColor6: '#FAF8F6',
		primaryColor7: '#D8B273',
		primaryColor8: '#E2C596',
		primaryColor9: '#ECD9B9',
		primaryColor10: '#F5EBDC',
		primaryColor11: '#FDFBF8',

		//! Secondary Colors
		successColor: '#28A94B',
		errorColor: '#E73738',
		warningColor: '#FF6900',

		//! Background Colors
		backgroundLightColor: '#FFFFFF',
		backgroundDarkColor: '#F6F2EE',
	},

	mediaQuery: {
		desktopSizeXL: '2560px',
		desktopSizeL: '1920px',
		desktopSizeM: '1536px',
		desktopSizeS: '1440px',
		desktopSizeXS: '1366px',
		tabletSize: '1280px',
		tabletSizeS: '768px',

		desktopSizeXLMin: '2559px',
		desktopSizeLMin: '1919px',
		desktopSizeMMin: '1535px',
		desktopSizeSMin: '1439px',
		desktopSizeXSMin: '1365px',
		tabletSizeMin: '1279px',
		tabletSizeSMin: '767px',
	},
};
