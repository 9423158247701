export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
		},

		services: {
			path: '/services',
			slug: 'services',
			name: 'ourServices',
		},

		singleServices: {
			path: '/services/:slug',
			slug: 'services',
			name: 'singleServices',
			singleType: 'singleServices',
		},

		team: {
			path: '/team',
			slug: 'team',
			name: 'team',
		},

		digitalPractices: {
			path: '/digital-practices',
			slug: 'digital-practices',
			name: 'digitalPractices',
		},

		philosophy: {
			path: '/philosophy',
			slug: 'philosophy',
			name: 'philosophy',
		},

		contacts: {
			path: '/contacts',
			slug: 'contacts',
			name: 'contacts',
		},

		dataProtection: {
			path: '/data-protection',
			slug: 'data-protection',
			name: 'dataProtection',
			headerHasBackground: true,
		},

		imprint: {
			path: '/imprint',
			slug: 'imprint',
			name: 'imprint',
			headerHasBackground: true,
		},
	},
};
