import { createGlobalStyle, css } from 'styled-components';
import localFont from 'next/font/local';

const workSans = localFont({
	src: [
		{
			path: 'fonts/WorkSansRegular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/WorkSansMedium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/WorkSansSemiBold.woff2',
			weight: '600',
			display: 'swap',
			style: 'normal',
		},
	],
});

function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

const Variables = createGlobalStyle`${css`
	:root {
		//! Font
		--workSans: ${workSans.style.fontFamily};

		//! Spacings
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		${spaceGenerator()}

		//! Transition
        --trTime: 0.3s;

		//! Line Heights
		--lineHeightS: 1.2;
		--lineHeightM: 1.4;
		--lineHeightL: 1.5;

		//! Primary Colors
		--primaryColor1: ${(props) => props.theme.colors.primaryColor1};
		--primaryColor2: ${(props) => props.theme.colors.primaryColor2};
		--primaryColor3: ${(props) => props.theme.colors.primaryColor3};
		--primaryColor4: ${(props) => props.theme.colors.primaryColor4};
		--primaryColor5: ${(props) => props.theme.colors.primaryColor5};
		--primaryColor6: ${(props) => props.theme.colors.primaryColor6};
		--primaryColor7: ${(props) => props.theme.colors.primaryColor7};
		--primaryColor8: ${(props) => props.theme.colors.primaryColor8};
		--primaryColor9: ${(props) => props.theme.colors.primaryColor9};
		--primaryColor10: ${(props) => props.theme.colors.primaryColor10};
		--primaryColor11: ${(props) => props.theme.colors.primaryColor11};

		//! Secondary Colors
		--successColor: ${(props) => props.theme.colors.successColor};
		--errorColor: ${(props) => props.theme.colors.errorColor};
		--warningColor: ${(props) => props.theme.colors.warningColor};

		//! Background Colors
		--backgroundLightColor: ${(props) => props.theme.colors.backgroundLightColor};
		--backgroundDarkColor: ${(props) => props.theme.colors.backgroundDarkColor};

		//! Screen Sizes
		--desktopSizeXL: ${(props) => props.theme.mediaQuery.desktopSizeXL};
		--desktopSizeL: ${(props) => props.theme.mediaQuery.desktopSizeL};
		--desktopSizeM: ${(props) => props.theme.mediaQuery.desktopSizeM};
		--desktopSizeS: ${(props) => props.theme.mediaQuery.desktopSizeS};
		--tabletSize: ${(props) => props.theme.mediaQuery.tabletSize};
		--tabletSizeS: ${(props) => props.theme.mediaQuery.tabletSizeS};

		//! Grid Column Distance
		--colPadding: calc(var(--gutter) / 2);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXL}) {
		:root {
			--h1: 152px;
			--h2: 104px;
			--h3: 80px;
			--h4: 72px;
			--h5: 56px;
			--h6: 32px;
			--p1: 24px;
			--p2: 20px;
			--p3: 16px;
			--p4: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp13x);
			--gutter: var(--sp6x);
			--sectionDistance: var(--sp30x);
			--headerHeight: 136px;
			--getInTouchSectionPadTB: var(--sp25x);
			--notFoundDescMTop: var(--sp3x);
			--notFoundBtnMTop: var(--sp8x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		:root {
			--h1: 120px;
			--h2: 80px;
			--h3: 64px;
			--h4: 56px;
			--h5: 40px;
			--h6: 24px;
			--p1: 20px;
			--p2: 16px;
			--p3: 14px;
			--p4: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp10x);
			--gutter: var(--sp5x);
			--sectionDistance: var(--sp25x);
			--headerHeight: 106px;
			--getInTouchSectionPadTB: var(--sp20x);
			--notFoundDescMTop: var(--sp2x);
			--notFoundBtnMTop: var(--sp6x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		:root {
			--h1: 88px;
			--h2: 64px;
			--h3: 56px;
			--h4: 48px;
			--h5: 32px;
			--h6: 20px;
			--p1: 18px;
			--p2: 16px;
			--p3: 14px;
			--p4: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp8x);
			--gutter: var(--sp4x);
			--sectionDistance: var(--sp20x);
			--headerHeight: 90px;
			--getInTouchSectionPadTB: var(--sp16x);
			--notFoundDescMTop: var(--sp1-5x);
			--notFoundBtnMTop: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		:root {
			--h1: 80px;
			--h2: 56px;
			--h3: 48px;
			--h4: 40px;
			--h5: 28px;
			--h6: 18px;
			--p1: 18px;
			--p2: 16px;
			--p3: 14px;
			--p4: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp7x);
			--gutter: var(--sp3-5x);
			--sectionDistance: var(--sp20x);
			--headerHeight: 82px;
			--getInTouchSectionPadTB: var(--sp16x);
			--notFoundDescMTop: var(--sp2x);
			--notFoundBtnMTop: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		:root {
			--h1: 80px;
			--h2: 56px;
			--h3: 48px;
			--h4: 40px;
			--h5: 28px;
			--h6: 18px;
			--p1: 18px;
			--p2: 16px;
			--p3: 14px;
			--p4: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp7x);
			--gutter: var(--sp3-5x);
			--sectionDistance: var(--sp18x);
			--headerHeight: 82px;
			--getInTouchSectionPadTB: var(--sp16x);
			--notFoundDescMTop: var(--sp2x);
			--notFoundBtnMTop: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		:root {
			--h1: 64px;
			--h2: 56px;
			--h3: 40px;
			--h4: 32px;
			--h5: 24px;
			--h6: 16px;
			--p1: 16px;
			--p2: 14px;
			--p3: 12px;
			--p4: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp6x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp13x);
			--headerHeight: 79px;
			--getInTouchSectionPadTB: var(--sp10x);
			--notFoundDescMTop: var(--sp1-5x);
			--notFoundBtnMTop: var(--sp4x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		:root {
			--h1: 64px;
			--h2: 56px;
			--h3: 40px;
			--h4: 32px;
			--h5: 24px;
			--h6: 16px;
			--p1: 16px;
			--p2: 14px;
			--p3: 12px;
			--p4: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp6x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp13x);
			--headerHeight: 75px;
			--getInTouchSectionPadTB: var(--sp10x);
			--notFoundDescMTop: var(--sp1-5x);
			--notFoundBtnMTop: var(--sp4x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		:root {
			--h1: 28px;
			--h2: 24px;
			--h3: 24px;
			--h4: 20px;
			--h5: 20px;
			--h6: 14px;
			--p1: 14px;
			--p2: 14px;
			--p3: 12px;
			--p4: 12px;

			//! Global Variables
			--contPaddingLR: var(--sp2x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp10x);
			--headerHeight: 64px;
			--getInTouchSectionPadTB: var(--sp6x);
			--notFoundDescMTop: var(--sp1x);
			--notFoundBtnMTop: var(--sp4x);
		}
	}
`}`;

export default Variables;
