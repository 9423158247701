'use client';

import { useState, createContext, useCallback } from 'react';

//* Context
import withLanguageContext from '../consumerHOC/LanguageConsumer';

//* Helpers
import { api } from '@/helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
	const [state, setState] = useState({
		...props.newData,
		prevRoute: null,
	});

	const getGlobalData = useCallback(() => {
		return state.globalData[props.selectedLang];
	}, [state, props.selectedLang]);

	const enableLoading = () => {
		this.setState({ loading: true });
	};

	const disableLoading = () => {
		setState((prev) => ({ ...prev, loading: false }));
	};

	const contactUs = (data) => {
		return api.post('contact-us', data);
	};

	const methods = {
		getGlobalData,
		enableLoading,
		disableLoading,
		contactUs,
	};

	return <DataContext.Provider value={{ ...state, ...methods }}>{props.children}</DataContext.Provider>;
};

export default withLanguageContext(DataProvider, ['selectedLang']);
