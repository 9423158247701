import styled from 'styled-components';

const ContainerStyle = styled.section`
	width: 100%;
	margin: 0 auto;

	&:not(.full) {
		width: 100%;
		padding-left: var(--contPaddingLR);
		padding-right: var(--contPaddingLR);
	}

	&.section-distance {
		margin-top: var(--sectionDistance);
	}

	&.full {
		padding-left: 0;
		padding-right: 0;
	}
`;
export default ContainerStyle;
