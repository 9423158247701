import api from './_api';
import config from './_config';
import { getRequestCookies, getCookie } from './_functions';

export const getPageParams = (lang, router) => getRouteObjFromConfig(lang, router);

export async function getData({ lang, state, router, getPageParams = false, update = false }) {
	const accessToken = getRequestCookies(router, 'accessToken') || getCookie('accessToken');
	const pageParams = getRouteObjFromConfig({ lang, router });

	let newState = state;
	let promiseObj = {};

	if (!state.global?.[lang] && router.pathname !== '/404') {
		promiseObj.global = getGlobal(lang);
	}

	if (pageParams) {
		if (pageParams.name && pageParams.slug && !pageParams.singleSlug && ((!state.pages?.[pageParams.name]?.[lang] && pageParams.autoload) || update || pageParams.always)) {
			promiseObj.pages = getPage(pageParams.slug, lang, {}, accessToken);
		}

		if (pageParams?.singleSlug && pageParams.autoload && router.query.slug && (!state?.[pageParams.singleType]?.[router.query.slug]?.[lang] || pageParams.always)) {
			promiseObj.singleItem = getPage(`${pageParams.slug}/${router.query.slug}`, lang, pageParams.queryParams, accessToken);
		}

		if (pageParams.postType && pageParams.postType.slug && (!state?.[pageParams.postType.name]?.[pageParams.queryName]?.[lang] || pageParams.postType.always)) {
			promiseObj.postType = getPostType(pageParams.postType.slug, lang, pageParams.postType.queryParams, accessToken);
		}
	}

	const promises = Object.values(promiseObj);

	promises.length > 0 &&
		(await Promise.all(promises)
			.then((res) => {
				const keys = Object.keys(promiseObj);

				keys.forEach((el, k) => {
					const type = el === 'pages' ? 'name' : el === 'postType' ? 'queryName' : null;

					newState = updateState({
						lang,
						stateKey: el === 'postType' ? pageParams.postType.name : el == 'singleItem' ? pageParams?.singleType : el,
						response: res[k],
						type: el === 'singleItem' ? router.query.slug : type && pageParams[type],
						state: newState,
					});
				});
			})
			.catch((err) => {
				console.error('Get Data Catch in Line 52');
				console.log(err);
				if (typeof window === 'undefined' && router.res.writeHead) {
					router.res.writeHead(302, { Location: '/404' });
					router.res.end();
				}
			}));

	return getPageParams ? { ...newState, pageParams } : newState;
}

const getGlobal = async (lang) => {
	return await api.get('global-data', lang).then((res) => {
		return res.data;
	});
};

const getPage = (slug, lang, queryParams = {}, accessToken) => {
	return api.get(slug, lang, queryParams, accessToken).then((res) => {
		return res;
	});
};

const getPostType = (slug, lang, queryParams = {}, accessToken) => {
	return api.get(`${slug}`, lang, queryParams, accessToken).then((res) => {
		return res;
	});
};

const getRouteObjFromConfig = ({ lang, router }) => {
	let path = router.asPath.split('?')[0];

	const regex = new RegExp(`^\/${lang}`, '');
	path = path.replace(regex, '');

	if (router.query.slug) {
		path = path.replace(router.query.slug + '/', '') + ':slug';
	}

	if (router.query.category) {
		path = path.replace(router.query.category + '/', ':category/');
	}

	const removedLastPath = path.substr(0, path.lastIndexOf('/'));

	let route = JSON.parse(
		JSON.stringify(
			Object.values(config.routes).filter((r) => {
				const pathArr = r.path.split(':');

				return r.path === path || r.path.replace(/\/$/, '') === path.replace(/\/$/, '') || (pathArr[0].replace(/\/$/, '') === removedLastPath && pathArr[1] && r.autoload);
			})[0] || {}
		)
	);

	route =
		router.query.slug || router.query?.params?.length === 2
			? {
					...route,
					singleSlug: true,
					selectedSlug: router.query.slug ? router.query.slug : router.query?.params?.length === 2 && router.query.params[1],
			  }
			: route;

	//! For Query Params
	let queryParams = {};
	let queryName = 'default';

	if (route?.queryParamKeys) {
		for (const q of route.queryParamKeys) {
			if (router.query[q]) {
				queryName = `${queryName !== 'default' ? queryName + '&' : ''}${q}=${router.query[q]}`;
				queryParams[q] = router.query[q];
			}
		}

		route.queryParams = queryParams;
	}

	if (route?.postType?.queryParamKeys) {
		for (const q of route.postType.queryParamKeys) {
			if (router.query[q]) {
				queryName = `${queryName !== 'default' ? queryName + '&' : ''}${q}=${router.query[q]}`;
				queryParams[q] = router.query[q];
			}
		}

		route.postType.queryParams = queryParams;
		route.queryName = queryName;
	}

	if (router.query.category) {
		route.slug = route.slug.replace(':category', router.query.category);

		if (route.postType?.slug) {
			route.postType.slug = route.postType.slug.replace(':category', router.query.category);
			route.queryName = ':category-' + route.queryName;
			route.queryName = route.queryName.replace(':category', router.query.category);
		}
	}

	return router.asPath === '404' ? false : route;
};

const updateState = ({ state, lang, stateKey, response, type }) => {
	return {
		...state,
		[stateKey]: type
			? {
					...state?.[stateKey],
					[type]: { ...state?.[stateKey]?.[type], [lang]: response },
			  }
			: { ...state[stateKey], [lang]: response },
	};
};
