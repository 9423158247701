import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';

	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% }`;
	}

	return style;
}

const HelperClass = createGlobalStyle`${css`
	//! Fonts
	.workSans-regular {
		font-family: var(--workSans);
		font-weight: 400;
	}

	.workSans-medium {
		font-family: var(--workSans);
		font-weight: 500;
	}

	.workSans-semibold {
		font-family: var(--workSans);
		font-weight: 600;
	}

	//! Primary Colors
	.primary-color1 {
		color: var(--primaryColor1);

		&-bg {
			background-color: var(--primaryColor1);
		}
	}

	.primary-color2 {
		color: var(--primaryColor2);

		&-bg {
			background-color: var(--primaryColor2);
		}
	}

	.primary-color3 {
		color: var(--primaryColor3);

		&-bg {
			background-color: var(--primaryColor3);
		}
	}

	.primary-color4 {
		color: var(--primaryColor4);

		&-bg {
			background-color: var(--primaryColor4);
		}
	}

	.primary-color5 {
		color: var(--primaryColor5);

		&-bg {
			background-color: var(--primaryColor5);
		}
	}

	.primary-color6 {
		color: var(--primaryColor6);

		&-bg {
			background-color: var(--primaryColor6);
		}
	}

	.primary-color7 {
		color: var(--primaryColor7);

		&-bg {
			background-color: var(--primaryColor7);
		}
	}

	.primary-color8 {
		color: var(--primaryColor8);

		&-bg {
			background-color: var(--primaryColor8);
		}
	}

	.primary-color9 {
		color: var(--primaryColor9);

		&-bg {
			background-color: var(--primaryColor9);
		}
	}

	.primary-color10 {
		color: var(--primaryColor10);

		&-bg {
			background-color: var(--primaryColor10);
		}
	}

	.primary-color11 {
		color: var(--primaryColor11);

		&-bg {
			background-color: var(--primaryColor11);
		}
	}

	//! Secondary Colors
	.success-color {
		color: var(--successColor);

		&-bg {
			background-color: var(--successColor);
		}
	}

	.error-color {
		color: var(--errorColor);

		&-bg {
			background-color: var(--errorColor);
		}
	}

	.warning-color {
		color: var(--warningColor);

		&-bg {
			background-color: var(--warningColor);
		}
	}

	//! Background Colors
	.background-light-color {
		color: var(--backgroundLightColor);

		&-bg {
			background-color: var(--backgroundLightColor);
		}
	}

	.background-dark-color {
		color: var(--backgroundDarkColor);

		&-bg {
			background-color: var(--backgroundDarkColor);
		}
	}

	//! Text capitalize
	.uppercase {
		text-transform: uppercase;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.underline {
		text-decoration: underline;
	}

	.line-through {
		text-decoration-line: line-through;
	}

	//! No wrap
	.no-wrap {
		white-space: nowrap;
	}

	//! Opacity zero
	.opacity-zero {
		opacity: 0 !important;
	}

	//! Cursor pointer
	.cursor-pointer {
		cursor: pointer;
	}

	//! Display none
	.display-none {
		display: none;
	}

	//! Pointer none
	.pointer-none {
		pointer-events: none;
	}

	//! Line Animation
	.line-anim {
		position: relative;
		overflow: hidden;
		display: inline-flex;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: var(--primaryColor1);
			transition: left 0.7s ease-in-out;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: -130%;
			width: 100%;
			height: 1px;
			background-color: var(--primaryColor1);
			transition: left 0.7s ease-in-out;
		}

		@media (hover: hover) {
			&:hover {
				&:before {
					left: 130%;
				}

				&:after {
					left: 0%;
				}
			}
		}
	}

	//! Burger Menu Line Animations
	.burger-menu-line {
		position: relative;
		overflow: hidden;
		display: inline-flex;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 1px;
			transition: width var(--trTime) ease-out;
		}

		&.version1 {
			&:before {
				background-color: var(--primaryColor1);
			}
		}

		&.version2 {
			&:before {
				background-color: var(--backgroundLightColor);
			}
		}

		@media (hover: hover) {
			&:hover {
				&:before {
					width: 100%;
				}
			}
		}
	}

	//! Not Found Styles
	.not-found {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.not-found-inner {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			.not-found-description {
				margin-top: var(--notFoundDescMTop);
			}

			.btn-wrap {
				margin-top: var(--notFoundBtnMTop);
			}
		}
	}

	//! SR Only
	.sr-only {
		position: absolute !important;
		clip: rect(1px, 1px, 1px, 1px);
		width: 1px !important;
		height: 1px !important;
		padding: 0 !important;
		border: 0 !important;
		overflow: hidden;
		white-space: nowrap;
	}

	//! Grid
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		${columnGenerator()}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeXLMin}) {
			${columnGenerator('dl')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeLMin}) {
			${columnGenerator('dm')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeMMin}) {
			${columnGenerator('ds')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeSMin}) {
			${columnGenerator('dxs')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeXSMin}) {
			${columnGenerator('t')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeMin}) {
			${columnGenerator('ts')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}
`}`;

export default HelperClass;
